<template>
    <LiefengContent>
        <!-- :isBack="$route.query.isback" @backClick="$router.push('/mobilizationsystem')" -->
        <template v-slot:title>
            社区访问用户
            <span class="eye" @click.stop="changeEye">
                <Icon :type="hasSensitive ? 'md-eye-off' : 'md-eye'" />
            </span>
        </template>
        <template v-slot:toolsbarRight>
            <Form :label-colon="true" :inline="true" class="search">
                <FormItem>
                    <Cascader
                        transfer
                        @on-change="changeCasder"
                        :load-data="loadData"
                        change-on-select
                        style="margin-right: 10px; width: 250px"
                        :data="CascaderList"
                        v-model="CascaderId"
                        placeholder="请选择区/街道/社区"
                    ></Cascader>
                </FormItem>
                <FormItem>
                    <Input :maxlength="20" style="margin-right: 10px; width: 150px" placeholder="按姓名查询" v-model.trim="selectForm.name" />
                </FormItem>
                <FormItem>
                    <Input :maxlength="20" style="margin-right: 10px; width: 150px" placeholder="按手机号查询" v-model.trim="selectForm.mobile" />
                </FormItem>
                <FormItem>
                    <Select transfer v-model="selectForm.activeStatus" placeholder="按激活状态查询" style="width: 150px">
                        <Option value="">全部</Option>
                        <Option value="1">是</Option>
                        <Option value="2">否</Option>
                    </Select>
                </FormItem>
                <Button @click="inputSearch" style="margin-right: 10px" type="primary" icon="ios-search">查询</Button>
                <Button type="success" style="margin-right: 10px" @click="reset" icon="ios-refresh">重置</Button>
            </Form>

            <!-- <Button type="primary" @click="openMessage">发短信</Button>
      <Button type="primary" @click="$router.push('/smsrecordindex?name=mobilizationindex')">短信记录</Button> -->
            <!-- <Button type="error" @click="importExcel" icon="ios-add-circle-outline"
        >导入</Button
      > -->
            <!-- <Button
          type="error"
          icon="ios-arrow-back"
           @click=""
          :style="{display: $route.query.isback ? '' : 'none'}"
          >返回</Button
        > -->
        </template>
        <template v-slot:contentArea>
            <LiefengTable
                :talbeColumns="talbeColumns"
                :tableData="tableData"
                height="200"
                :loading="loading"
                :fixTable="true"
                :pagesizeOpts="[20, 30, 50, 100]"
                :curPage="page"
                :total="total"
                :page-size="pageSize"
                @hadlePageSize="hadlePageSize"
            ></LiefengTable>

            <!-- 导入excel弹窗 -->
            <LiefengModal title="导入" width="40%" :value="excelStatus" @input="excelModalData" class="export-modal">
                <template v-slot:contentarea>
                    <Form :model="searchForm" :label-width="100">
                        <FormItem>
                            <span slot="label" class="validate">请选择社区:</span>
                            <Tooltip placement="right-start" style="width: 100%">
                                <div slot="content" style="width: 200px; white-space: normal">由于社区太多，请输入关键字进行刷选</div>
                                <Select v-model="nodeId" filterable @on-change="getTree" placeholder="点击列表/输入搜索" :remote-method="loadingCommunityFn" :loading="loadingCommunity">
                                    <!-- 防止报错，使用了index -->
                                    <Option v-for="(item, index) in nodeList" :value="item.value" :key="index">{{ item.label }}</Option>
                                </Select>
                            </Tooltip>
                        </FormItem>
                    </Form>

                    <LiefengUploadExcel
                        style="margin: 20px 0"
                        :showDownModal="{ isShow: true, downLink: './excelfiles/mobilization.xlsx', downName: '社区动员组织管理导入模板' }"
                        :action="exportAction"
                        :uploadData="uploadData"
                        :isShow="showExcel"
                        @success="excelSuccess"
                    ></LiefengUploadExcel>
                </template>
            </LiefengModal>
            <!--发短信-->
            <LiefengModal :value="messageStatus" @input="messageInput" title="发短信" :fullscreen="false" width="800px" height="540px">
                <template v-slot:contentarea>
                    <Message @SmsUser="SmsUser" ref="message" :isShow="showNumber"></Message>
                </template>
                <template v-slot:toolsbar>
                    <Button type="info" @click="messageBack" style="margin-right: 10px">取消</Button>
                    <Button style="margin: 0 5px 0 13px" :disabled="sendDisabled" type="primary" @click="messageSend">确定发送</Button>
                </template>
            </LiefengModal>

            <!--设置负责区域-->
            <LiefengModal :value="messageRegion" @input="regionInput" title="设置负责区域" :fullscreen="false" width="800px" height="540px">
                <template v-slot:contentarea>
                    <Region :userId="regionUserId" ref="region" v-if="showRegion"></Region>
                </template>
                <template v-slot:toolsbar>
                    <Button type="info" @click="regionInput(false)" style="margin-right: 10px">取消</Button>
                    <Button style="margin: 0 5px 0 13px" :disabled="sendDisabled" type="primary" @click="sendRegion">确定</Button>
                </template>
            </LiefengModal>

            <CompModal title="畅联通" ref="modal" width="400px" @on-close="iframeUrl = null">
                <iframe v-if="iframeUrl" :src="iframeUrl" width="100%" height="100%" frameborder="0" style="display: block"></iframe>
            </CompModal>
        </template>
    </LiefengContent>
</template>

<script>
import LiefengContent from "@/components/LiefengContent3"
import LiefengTable from "@/components/LiefengTable"
import LiefengModal from "@/components/LiefengModal"
import LiefengUploadExcel from "./children/LiefengUploadExcel"
import Message from "./children/message.vue"
import Region from "./children/region.vue"
import CompModal from "../residentdatabase/components/CompModal.vue"
import proxy from "@/api/proxy"
export default {
    data() {
        return {
            // 脱敏
            hasSensitive: true,
            // 列表搜索
            selectForm: {
                name: "",
                activeStatus: "",
                mobile: "",
            },
            tableData: [],
            talbeColumns: [
                {
                    title: "区",
                    key: "zoneName",
                    width: 180,
                    align: "center",
                },
                {
                    title: "街道",
                    key: "streetName",
                    width: 180,
                    align: "center",
                },
                {
                    title: "社区名称",
                    key: "communityName",
                    width: 180,
                    align: "center",
                },
                {
                    title: "姓名",
                    key: "name",
                    width: 100,
                    align: "center",
                },
                {
                    title: "性别",
                    key: "sex",
                    width: 100,
                    align: "center",
                    render: (h, params) => {
                        return h("div", {}, params.row.sex == 0 ? "未知" : params.row.sex == 1 ? "男" : params.row.sex == 2 ? "女" : "未知")
                    },
                },
                {
                    title: "联系电话",
                    key: "mobile",
                    width: 130,
                    align: "center",
                },
                {
                    title: "出生日期",
                    key: "birthday",
                    align: "center",
                    width: 120,
                    render: (h, params) => {
                        return h("div", {}, params.row.birthday ? this.$core.formatDate(new Date(params.row.birthday), "yyyy-MM-dd") : "")
                    },
                },
                {
                    title: "现居地址",
                    key: "addr",
                    minWidth: 180,
                    align: "center",
                },
                {
                    title: "激活时间",
                    minWidth: 180,
                    align: "center",
                    render: (h, params) => {
                        return h("div", {}, params.row.gmtCreate ? this.$core.formatDate(new Date(params.row.gmtCreate), "yyyy-MM-dd hh:mm:ss") : "")
                    },
                },
                // {
                //    title: "负责区域",
                //     key: "regionName",
                //     width: 180,
                //     align: "center",
                // },
                {
                    title: "激活状态",
                    key: "platformUser",
                    width: 120,
                    align: "center",
                    render: (h, params) => {
                        return h("div", {}, params.row.platformUser ? (params.row.platformUser == 1 ? "是" : params.row.platformUser == 2 ? "否" : "") : "")
                    },
                },
                {
                    title: "操作",
                    key: "action",
                    align: "center",
                    width: 100,
                    fixed: "right",
                    render: (h, params) => {
                        return h("div", [
                            h(
                                "Button",
                                {
                                    props: {
                                        type: "info",
                                        size: "small",
                                    },
                                    on: {
                                        click: () => {
                                            this.onOpenMessageDetail(params.row)
                                        },
                                    },
                                },
                                "发消息"
                            ),
                            // h(
                            //   "Button",
                            //   {
                            //     props: {
                            //       type: "info",
                            //       size: "small",
                            //     },
                            //     style: {
                            //       marginLeft: "14px",
                            //     },
                            //     on: {
                            //       click: () => {

                            //       },
                            //     },
                            //   },
                            //   "修改"
                            // ),
                            // h(
                            //   "Button",
                            //   {
                            //     props: {
                            //       type: "info",
                            //       size: "small",
                            //     },
                            //     style: {
                            //       marginLeft: "14px",
                            //     },
                            //     on: {
                            //       click: () => {
                            //            this.$Modal.confirm({
                            //             title: "温馨提示",
                            //             content: "您正在删除此数据，请确定是否继续?",
                            //             onOk: () => {
                            //                this.$post('/syaa/pc/sy/user/communityStructure/delete',{
                            //                  id:params.row.id
                            //                }).then(res=>{
                            //                  if(res.code == 200){
                            //                    this.$Message.success({
                            //                      content:'删除成功',
                            //                      background:true
                            //                    })
                            //                    this.page = this.currentPage
                            //                    this.getList(this.CascaderId)
                            //                  }else{
                            //                    this.$Message.error({
                            //                      content:res.desc,
                            //                      background:true
                            //                    })
                            //                    return
                            //                  }
                            //                })
                            //             }
                            //           });
                            //       },
                            //     },
                            //   },
                            //   "删除"
                            // ),
                        ])
                    },
                },
            ],
            loading: false,
            //当前页
            page: 1,
            total: 0,
            pageSize: 20,
            currentPage: 0,
            CascaderList: [],
            CascaderId: [],

            // 导入的参数
            excelStatus: false,
            exportAction: "",
            uploadData: {
                orgCode: "",
                oemCode: parent.vue.oemInfo.oemCode,
            },

            showExcel: 0,
            nodeList: [],
            //异步加载社区
            loadingCommunity: false,
            nodeId: "",
            allNodeList: [],

            // 发消息
            messageStatus: false,
            showNumber: 0,
            sendDisabled: false,

            //  设置负责区域
            messageRegion: false,
            regionUserId: {},
            showRegion: false,
            // 类型数组
            // typeList:[]
             iframeUrl: null,
        }
    },
    methods: {
        onOpenMessageDetail(item) {
            const loading = this.$Message.loading({
                content: "连接中...",
                duration: 0,
            })

            this.$get("/gateway/chat/app/chat/onlyUser/flushUser", {
                onlyUserId: item.custGlobalId,
            })
                .then(res => {
                    if (res.code !== "200" || !res.data) {
                        return this.$Message.error(res.desc || res.msg || "建立连接失败，请稍后再试！")
                    }

                    this.iframeUrl = `${proxy["/custom"].target}/#/pages/im/person/chat?groupCode=${res.data}&groupName=${item.name}&businessType=1&businessCode=2&custGlobalId=${
                        item.custGlobalId
                    }&token=${window.sessionStorage.getItem("accessToken")}`
                })
                .finally(() => {
                    loading()
                })
        },
        sendRegion() {
            let data = this.$refs.region
            if (data.menuName == "grid") {
                if (data.tableData.gridIdList.length == 0) {
                    this.$Message.warning({
                        content: "请选择网格数据",
                        background: true,
                    })
                    return
                }
                let parmas = {
                    gridIdList: data.tableData.gridIdList,
                    regionIdList: [],
                    houseCodeList: [],
                }
                this.structureRegion(parmas)
                // 如果是网格的
            } else if (data.menuName == "build") {
                // 如果是楼栋的
                if (data.tableData.regionGovernmentVos.length == 0) {
                    this.$Message.warning({
                        content: "请选择楼栋",
                        background: true,
                    })
                    return
                }
                let arr = []
                data.tableData.regionGovernmentVos.map(item => {
                    arr.push(item.id)
                })
                let parmas = {
                    gridIdList: [],
                    regionIdList: arr,
                    houseCodeList: [],
                }
                this.structureRegion(parmas)
            } else if (data.menuName == "hu") {
                // 如果是户的
                if (data.tableData.houseGovernmentVos.length == 0) {
                    this.$Message.warning({
                        content: "请选择户",
                        background: true,
                    })
                    return
                }
                let arr = []
                data.tableData.houseGovernmentVos.map(item => {
                    arr.push(item.code)
                })
                let parmas = {
                    gridIdList: [],
                    regionIdList: [],
                    houseCodeList: arr,
                }
                this.structureRegion(parmas)
            }
        },
        //   负责区域模态框关闭
        regionInput(status) {
            if (!status) {
                this.messageRegion = status
                this.regionUserId = {}
                this.showRegion = false
            } else {
                this.messageRegion = status
            }
        },
        // 点击发消息按钮
        openMessage() {
            this.messageStatus = true
            ++this.showNumber
            this.sendDisabled = false
        },
        // 发消息
        messageInput(status) {
            this.messageStatus = status
        },
        // 弹窗返回按钮
        messageBack() {
            this.messageStatus = false
        },
        // 弹窗确认按钮
        messageSend() {
            if (this.$refs.message.selectList.length == 0) {
                this.$Message.warning({
                    content: "请选择接收人",
                    background: true,
                })
                return
            }
            if (this.$refs.message.formData.content == "") {
                this.$Message.warning({
                    content: "请填写短信内容",
                    background: true,
                })
                return
            }
            let data = []
            this.$refs.message.selectList.map(item => {
                data.push(item.id)
            })
            //   this.sendRow(data)
        },
        sendRow(data) {
            //   this.$post('/syaa/pc/sy/user/userOriginal/sendUserOriginalSms',{
            //     staffOrgCode:parent.vue.loginInfo.userinfo.orgCode,
            //     staffName:parent.vue.loginInfo.userinfo.realName,
            //     businessCode:'2',
            //     oemCode:parent.vue.oemInfo.oemCode,
            //     smsMsg:this.$refs.message.formData.content,
            //     ids:data.join(',')
            //   }).then(res=>{
            //     if(res.code == 200){
            //       this.messageStatus = false
            //       this.$Message.success({
            //         content:'短信发送成功',
            //         background:true
            //       })
            //     }else{
            //       this.$Message.error({
            //         content:res.desc,
            //         background:true
            //       })
            //       return
            //     }
            //   })
        },
        // 导入成功回调事件
        excelSuccess() {
            this.excelStatus = false
            this.page = 1
            this.pageSize = 20
            this.getList()
        },
        //修改excel导入弹窗状态
        excelModalData(status) {
            this.excelStatus = status
            // this.showExcel = false
        },
        // 点击导入按钮
        importExcel() {
            this.nodeId = ""
            this.uploadData.orgCode = ""
            this.excelStatus = true
            this.showExcel = this.showExcel + 1
            this.exportAction = window.vue.getProxy()["/syaa"].target + "/upload/sy/user/imp/importUserImp"
        },
        hadlePageSize(val) {
            this.page = val.page
            this.pageSize = val.pageSize
            this.getList(this.CascaderId)
        },
        // 搜索
        inputSearch(val) {
            if (this.CascaderId.length != 0 && this.CascaderId.length != 3) {
                this.$Message.warning({
                    content: "请选择到社区进行查询",
                    background: true,
                })
                return
            } else {
                this.getList(this.CascaderId)
            }
            if (this.CascaderId.length == 0) this.getList()
        },
        //重置
        reset() {
            this.pageNum = 1
            this.pageSize = 20
            this.selectForm.name = ""
            this.selectForm.mobile = ""
            this.selectForm.activeStatus = ""
            this.CascaderId = []
            this.getList()
        },
        //分页
        getList(data) {
            this.loading = true
            this.$get("/syaa/pc/sy/user/communityStructure/selectUserStructurePageV2", {
                page: this.page,
                pageSize: this.pageSize,
                staffOrgCode: parent.vue.loginInfo.userinfo.orgCode,
                custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
                orgCode: data && data.length != 0 && data.length == 3 ? data[data.length - 1] : "",
                mobile: this.selectForm.mobile,
                name: this.selectForm.name,
                platformUser: this.selectForm.activeStatus,
                busiCode: 2,
                hasSensitive: this.hasSensitive,
            })
                .then(res => {
                    this.loading = false
                    if (res.code == 200) {
                        if (res.dataList && res.dataList.length != 0) {
                            res.dataList.map(item => {
                                let label = ""
                                if (item.userItems && item.userItems.length != 0) {
                                    item.userItems.map(items => {
                                        label = items.labelName + "," + label
                                    })
                                }
                                item.labelName = label
                            })
                        }
                        this.tableData = res.dataList
                        this.total = res.maxCount
                        this.currentPage = res.currentPage
                    } else {
                        this.$Message.error({
                            content: res.desc,
                            background: true,
                        })
                        return
                    }
                })
                .catch(err => {
                    this.$Message.error({
                        content: err,
                        background: true,
                    })
                })
        },
        changeEye() {
            this.hasSensitive = !this.hasSensitive
            this.getList()
        },
        // 获取级联
        getCascader() {
            this.$get("/orgzz/pc/organization/selectCascadeDataScope", {
                custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
                oemCode: parent.vue.oemInfo.oemCode,
                orgCode: "4401",
                orgCodeSelf: parent.vue.loginInfo.userinfo.orgCode,
            }).then(res => {
                if (res.code == 200 && res.dataList.length != 0) {
                    this.CascaderList = this.forMatterTree(res.dataList)
                    this.CascaderList.map(item => {
                        item.loading = false
                    })
                }
            })
        },

        forMatterTree(data) {
            for (var i = 0; i < data.length; i++) {
                data[i].label = data[i].orgName
                data[i].value = data[i].orgCode
                if (data[i].children && data[i].children != null && data[i].children.length > 0) {
                    this.forMatterTree(data[i].children)
                }
            }
            return data
        },
        loadData(item, callback) {
            item.loading = true
            let params = {
                custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
                oemCode: parent.vue.oemInfo.oemCode,
                orgCode: item.orgCode,
                orgCodeSelf: parent.vue.loginInfo.userinfo.orgCode,
            }
            this.$get("/orgzz/pc/organization/selectCascadeDataScope", {
                ...params,
            }).then(res => {
                if (res.code == 200 && res.dataList.length != 0) {
                    let arr = this.forMatterTree(res.dataList)
                    arr.map(items => {
                        if (items.orgLevel == 4) {
                            items.loading = false
                        }
                    })
                    item.loading = false
                    item.children = arr
                    callback(item.children)
                } else {
                    callback([])
                }
            })
        },
        getSelect() {
            this.$get("/old/api/pc/project/selectStaffManagerCommunity", {
                staffId: parent.vue.loginInfo.userinfo.id,
                orgCode: parent.vue.loginInfo.userinfo.orgCode,
            })
                .then(res => {
                    if (res.code === "200" && res.dataList && res.dataList.length > 0) {
                        // this.getTree(this.communityCode);   //第一次进来默认获取第一个社区数据
                        this.nodeList = res.dataList.map(item => {
                            return {
                                value: item.code,
                                label: item.fullName,
                                projectCode: item.projectCode,
                            }
                        })
                        this.allNodeList = JSON.parse(JSON.stringify(this.nodeList))
                        if (this.nodeList.length > 50) {
                            this.nodeList.length = 50
                        }
                    }
                })
                .catch(err => {
                    console.log(err)
                })
        },
        getTree(val) {
            this.nodeId = val
            this.uploadData.orgCode = this.nodeId
        },
        //异步加载社区
        loadingCommunityFn(query) {
            if (query !== "" && !Number(query)) {
                this.loadingCommunity = true
                this.nodeList = this.allNodeList.filter(item => item.label.toLowerCase().indexOf(query.toLowerCase()) > -1)
                if (this.nodeList.length > 50) this.nodeList.length = 50
                this.loadingCommunity = false
            } else if (Number(query)) {
                return
            } else {
                this.nodeList = []
            }
        },
        // 设置区域范围接口
        structureRegion(data) {
            this.$post(
                "/syshequ/api/sytop/pc/grid/structureRoleSetting",
                {
                    ...this.regionUserId,
                    gridIdList: data.gridIdList,
                    regionIdList: data.regionIdList,
                    houseCodeList: data.houseCodeList,
                },
                { "Content-Type": "Application/json" }
            ).then(res => {
                if (res.code == 200) {
                    this.$Message.success({
                        content: "设置成功",
                        background: true,
                    })
                    this.messageRegion = false
                    this.showRegion = false
                    this.regionUserId = {}
                    this.page = this.currentPage
                    this.getList()
                } else {
                    this.$Message.error({
                        content: res.desc,
                        background: true,
                    })
                    return
                }
            })
        },
    },
    components: {
        LiefengContent,
        LiefengTable,
        LiefengModal,
        LiefengUploadExcel,
        Message,
        Region,
        CompModal
    },
    watch: {
        iframeUrl(v) {
            v && this.$refs.modal.display()
        },
    },
    async created() {
        this.getList()
        await this.getCascader()
        await this.getSelect()
    },
}
</script>
    
<style scoped lang='less'>
.modal {
    display: flex;
    padding: 0 20px;
    justify-content: space-between;
    flex-wrap: wrap;
    .ivu-form-item {
        width: 45%;
    }
}
/deep/.ivu-upload-select {
    display: block;
    margin: 10px 20px;
    border: 1px dotted rgb(15, 121, 241);
    cursor: pointer;
}

.phone {
    &::before {
        content: "*";
        display: inline-block;
        margin-right: 4px;
        line-height: 1;
        font-family: SimSun;
        font-size: 14px;
        color: #ed4014;
    }
}

/deep/.ivu-drawer-body {
    .ivu-form-item {
        width: 100%;
        margin-bottom: 0;
    }
}
.demo-drawer-footer {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    border-top: 1px solid #e8e8e8;
    padding: 10px 16px;
    text-align: right;
    background: #fff;
}
.export-modal {
    /deep/ #modal_contentarea {
        overflow: visible !important;
        height: 270px;
    }
}
/deep/.validate {
    &::before {
        content: "*";
        display: inline-block;
        margin-right: 4px;
        line-height: 1;
        font-family: SimSun;
        font-size: 14px;
        color: #ed4014;
    }
}
.eye {
    cursor: pointer;
    display: inline-block;
    font-size: 16px;
    margin: 0 15px 0;
}
</style>